import { Animate } from './Animate';
import { Cart } from './Cart';
import { Quote } from './Quote';

export class Product {
    readonly SERVICE = ''
    readonly ACTIVE = 'active';

    private cart: Cart;
    private quote: Quote;

    constructor(cart: Cart, quote: Quote) {
        this.cart = cart;
        this.quote = quote;

        if(document.getElementById('product')) {
            this.addEventListeners()
        }
    }

    private onCartButtonClick(event: SubmitEvent) {
        event.preventDefault();
        const button = event.currentTarget as HTMLElement;

        const id = parseInt(button.dataset.id);
        const quantity = parseInt(button.dataset.qty);
        Animate.blockElement(button);
        
        this.cart.addItem(id, quantity, 0, 1).then(
            () => Animate.unblockElement(button)
        );
    }

    private onQuantityButtonClick(element: HTMLButtonElement) {
        const input = element.parentElement.querySelector('input');
        const value = element.value == '1' ? 1 : -1;
        const newValue = parseInt(input.value) + value;
        input.value = newValue > 0 ? newValue.toString() : '1';
        this.onQuantityInputChange(input);
    }

    private onQuantityInputChange(element: HTMLInputElement) {
        (document.querySelector('#cart-button') as HTMLElement).dataset.qty = element.value;
    }

    private onQuoteButtonClick(element: HTMLButtonElement) {
        [...element.parentElement.children].forEach(element => {
            element.classList.toggle('invisible');
            element.classList.toggle('opacity-0');
        });

        return this.quote.toggle(element.parentElement.dataset.id);
    }

    public addEventListeners() { 
        const allTabs = [...document.querySelectorAll('#tabs > div')] as HTMLElement[]; 
        allTabs.forEach(e => {
            e.addEventListener('click', (event) => {
                const newTab = event.currentTarget as HTMLElement;
                if(newTab.classList.contains(this.ACTIVE)) {
                    return;
                }
                const currentTab = allTabs.find((tab) => tab.classList.contains(this.ACTIVE));
                if(currentTab) {
                    currentTab.classList.remove(this.ACTIVE);
                    newTab.classList.add(this.ACTIVE);
                    const currentContent = document.getElementById(currentTab.dataset.id) as HTMLElement;
                    const newContent = document.getElementById(newTab.dataset.id) as HTMLElement;
                    if(currentContent && newContent) {
                        Animate.slideUp(currentContent).then(() => Animate.slideDown(newContent));
                    }
                }
            });
        });

        document.querySelector('#cart-button')?.addEventListener('click', (e) => this.onCartButtonClick(e as SubmitEvent));

        document.querySelectorAll('#quantity button').forEach(element => {
            element.addEventListener('click', event => {
                this.onQuantityButtonClick(event.currentTarget as HTMLButtonElement);
            });
        });
        document.querySelector('#quantity input')?.addEventListener('change', e => this.onQuantityInputChange(e.currentTarget as HTMLInputElement));
        document.querySelectorAll('#quote button').forEach(element => {
            element.addEventListener('click', event => {
                this.onQuoteButtonClick(event.currentTarget as HTMLButtonElement);
            });
        });
    }
}