import { Ajax } from './Ajax';
import { Animate } from './Animate';
import { Cart } from './Cart';
import { Product } from './Product';
import { Quote } from './Quote';
import { Url } from './Url';

declare var ca: any;

export class Products {
    private cart: Cart
    private quote: Quote
    
    private filters: NodeListOf<HTMLElement>;
    private list: HTMLElement | null;


    constructor(cart: Cart, quote: Quote) {
        this.cart = cart;
        this.quote = quote;

        this.list = document.querySelector('#product-list') as HTMLElement;
        this.filters = document.querySelectorAll('.filter-input');

        if(this.list) {
            this.addListEventListeners();
        }
        if(this.filters) {
            this.addFilterEventListeners();
        }
    }

    private onCartButtonClick(elelemt: HTMLElement) {
        const id = parseInt(elelemt.dataset.id);
                
        this.cart.addItem(id, 1, 0, 1);
    }

    private onQuoteButtonClick(element: HTMLElement) {
        [...element.parentElement.children].forEach(element => {
            element.classList.toggle('invisible');
            element.classList.toggle('opacity-0');
        });

        return this.quote.toggle(element.parentElement.dataset.id);
    }

    private async onFilterChange() {
        const data = new FormData();
        data.append('action', 'CA_FilterProducts');

        this.filters.forEach((element: HTMLInputElement) => {
            if(element.type != 'checkbox' && element.value || (element.type && element.type == 'checkbox' && element.checked)) {
                data.append(element.name, element.value);
            }
        });

        const request = Ajax.requestForm(data);
        this.toggleAllProducts().then(() => {
            request.then(response => {
                [...this.list.children].forEach(e => e.remove());
                return response;
            }).then(response => this.handleResponse(response))
        });
    }

    private onClearGroupClick(element: HTMLElement) {
        const group = element.closest('.filter-group');
        group.querySelectorAll('.filter-input').forEach((input: HTMLInputElement) => {
            let isRefreshNeeded = false;
            if(input.type == "checkbox" && input.checked) {
                isRefreshNeeded = true;
                input.checked = false;
            } else if(input.type != 'checkbox' && input.value) {
                isRefreshNeeded = true;
                input.value = '';
            }
            if(isRefreshNeeded) {
                this.onFilterChange();
            }
        })
    }

    private onActiveFilterRemove(element: HTMLElement) {
        const input = document.getElementById(element.dataset.key) as HTMLInputElement;
        if(input.type == 'checkbox') {
            input.checked = false;
        } else {
            input.value = '';
        }
        element.remove();
    }

    private toggleAllProducts() {
        const products = [...this.list.querySelectorAll('.product')];
        let counter = 0;
        return new Promise((res) => {
            const recursion = () => {
                const product = products.shift();
                product?.classList?.toggle('opacity-0');
                counter++;
                products.length ? setTimeout(recursion, counter < 9 ? 25 : 0) : res(products);
            }
            recursion();
        });
    }

    private handleResponse(response: any) {
        if(response.success) {
            Url.set(response.url);

            document.querySelector('#active-filters').innerHTML = response.html.filters;
            document.querySelector('#pagination').innerHTML = response.html.pagination;
            document.querySelector('#count').innerHTML = response.html.count;
            if(response.html.searchError) {
                this.list.innerHTML = response.html.searchError;
            } else {
                const products = (new DOMParser()).parseFromString(response.html.products, 'text/html').querySelectorAll('.product');
                products.forEach(product => {
                    product.classList.add('opacity-0');
                    this.addProductEventListeners(product as HTMLElement);
                    this.list.append(product);
                });
                this.toggleAllProducts();
            }
            if(response.html.searchTitle) {
                document.querySelector('h1').innerText = response.html.searchTitle;
            }
            this.addActiveFiltersEventListeners();
        }
    }

    private onShowMoreClick(element: HTMLElement) {
        const parent = element.closest('.filter-group')
        const options = parent.querySelectorAll('.option');
        const showMoreAndLessButtons = parent.querySelectorAll('.show-more');
        options.forEach((option: HTMLElement, i: number) => {
            if(i > 4) {
                Animate.slideToggle(option);
            }
        });
        showMoreAndLessButtons.forEach(button => {
            button.classList.toggle('hidden');
        });
    }

    private onToggleFiltersClick() {
        const filters = document.querySelector('#product-filter') as HTMLElement;
        if(filters) {
            Animate.slideToggle(filters);
        }
    }

    private addListEventListeners() {
        this.list.querySelectorAll('.product').forEach(element => this.addProductEventListeners(element as HTMLElement))
    }

    private addProductEventListeners(product: HTMLElement) {
        product.querySelector('.add-to-cart')?.addEventListener('click', event => this.onCartButtonClick(event.currentTarget as HTMLElement));
        product.querySelectorAll('.add-to-quote').forEach(element => {
            element.addEventListener('click', event => this.onQuoteButtonClick(event.currentTarget as HTMLElement));
        });
    }

    private addFilterEventListeners() {
        this.filters.forEach(element => {
            element.addEventListener('change', event => this.onFilterChange());
        });
        document.querySelector('#toggle-filters')?.addEventListener('click', () => this.onToggleFiltersClick());
        document.querySelectorAll('.clear').forEach(element => element.addEventListener('click', event => this.onClearGroupClick(event.currentTarget as HTMLElement)));
        this.addActiveFiltersEventListeners();
        document.querySelectorAll('.show-more').forEach(element => element.addEventListener('click', event => this.onShowMoreClick(event.currentTarget as HTMLElement)));
    }

    private addActiveFiltersEventListeners() {
        const activeFilters = document.getElementById('active-filters');
        if(activeFilters) {
            activeFilters.querySelectorAll('.remove').forEach((element) => {
                element.addEventListener('click', (event) => {
                    this.onActiveFilterRemove(event.currentTarget as HTMLElement);
                    this.onFilterChange();
                });
            });
            activeFilters.querySelector('.clear')?.addEventListener('click', () => {
                activeFilters.querySelectorAll('.remove').forEach(element => this.onActiveFilterRemove(element as HTMLElement));
                this.onFilterChange();
            })
        }
    }
}