export class Ajax {
    static readonly URL = '/wp-admin/admin-ajax.php';

    static async request(data: any) {
        return fetch(this.URL, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => response.json());
    }

    static async requestForm(data: FormData) {
        return fetch(this.URL, {
            method: 'post',
            body: data
        }).then(response => response.json());
    }
}