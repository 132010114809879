import { Animate } from './Animate';

export class Messages {
    static readonly SUCCESS = 'success';
    static readonly ERROR = 'error';
    static readonly INFO = 'info';

    private static messages: HTMLElement | null;

    private static getContainer() {
        if(!this.messages) {
            this.messages =  document.getElementById('notices');
        }
        return this.messages;
    }
    public static async show(status: boolean, messages: Array<string>) {
        const container = this.getContainer();
        if(container && messages.length) {
            const [container, ul] = this.getElements(status);

            messages.forEach(message => {
                const li = document.createElement('li');
                li.innerHTML = message;

                ul.append(li);
            });

            return this.showContainer(container);
        }
    }

    public static hideWCMessages() {
        const container = this.getContainer();
        if(container) {
            container.querySelectorAll('.container').forEach(messages => Animate.slideDown(messages as HTMLElement).then(() => this.hide(messages as HTMLElement)));
        }
    }

    private static getElements(status: boolean) {
        const container = document.createElement('div');
        container.classList.add('container', 'content', 'py-1', 'shadow-md', 'rounded-md', 'text-white');
        container.classList.add(status ? 'bg-green' : 'bg-red');
        container.style.display = 'none';

        const ul = document.createElement('ul');
        ul.classList.add(this.getStatusClass(status));

        container.append(ul);

        return [container, ul];
    }

    private static getStatusClass(status: boolean) {
        return status ? this.SUCCESS : this.ERROR;
    }

    private static showContainer(container: HTMLElement) {
        this.getContainer().append(container);
        
        return Animate.slideDown(container).then(() => this.hide(container));
    }

    private static hide(container: HTMLElement) {
        return new Promise(res => {
            setTimeout(() => {
                Animate.slideUp(container).then(() => { 
                    container.remove(); 
                    res(container) 
                });
            }, 5000);
        });
    }

}