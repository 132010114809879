import { Ajax } from "./Ajax";

export class Account {
    readonly ACTIVE = 'active';

    constructor() {
        if(document.getElementById('account')) {
            this.addEventListeners();
        }
    }

    private async update(input: HTMLInputElement) {
        const name = input.name;
        const value = input.value;

        const data = new FormData();
        data.append('action', 'CA_UpdateUserField');
        data.append('name', name);
        data.append('value', value);

        return Ajax.requestForm(data).then(response => this.handleResponse(input, response));
    }

    private handleResponse(input: HTMLInputElement, response: any) {
        if(response.success) {
            input.parentNode.querySelector('small').innerHTML = '';
        } else {
            input.parentNode.querySelector('small').innerHTML = response.message;
        }
    }

    private addEventListeners() {
        [...document.querySelectorAll('#billing input, #shipping input')].forEach(e => {
            e.addEventListener('keyup', (event) => {
                this.update(event.currentTarget as HTMLInputElement);
            });
        });
    }
}