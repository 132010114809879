import { Animate } from "./Animate";

export class Modal {
    private currentAddToCartButton: HTMLElement;

    public add(modal: string) {
        const footer = document.querySelector('footer') as HTMLElement;
        footer?.insertAdjacentHTML('afterbegin', modal);
        return new Promise(res => {
            const modalElement = document.querySelector('#modal') as HTMLElement;
            if(modalElement) {
                setTimeout(() => {
                    this.show(modalElement);
                    [...modalElement.querySelectorAll('.close')].forEach(element => { 
                        element.addEventListener('click', () => this.remove());
                    });
                    [...modalElement.querySelectorAll('.modal-tab')].forEach(element => { 
                        element.addEventListener('click', (e) => this.changeTab(e.currentTarget as HTMLElement));
                    });
                    [...modalElement.querySelectorAll('.add-to-cart')].forEach(element => { 
                        element.addEventListener('click', (e) => {
                            this.currentAddToCartButton = e.currentTarget as HTMLElement;
                            const id = this.currentAddToCartButton.dataset['id'];
                            //@ts-ignore
                            ca.cart.addItem(id).then(response => { 
                                if(response.success) { 
                                    this.currentAddToCartButton.innerText = 'In Cart'; 
                                }
                            });                       
                        });
                    });
                    [...modalElement.querySelectorAll('.request-quote')].forEach(element => { 
                        element.addEventListener('click', (e) => {
                            this.currentAddToCartButton = e.currentTarget as HTMLElement;
                            //@ts-ignore
                            ca.product.updateQuoteList(e.currentTarget).then(response => { 
                                if(response.success) { 
                                    this.currentAddToCartButton.innerText = 'In Cart'; 
                                }
                            });                       
                        });
                    });
                }, 5);
            }
        });
    }

    public remove() {
        return new Promise((res, err) => {
            const modal = document.querySelector('#modal') as HTMLElement;
            if(modal) {
                this.hide(modal);
                setTimeout(() => { 
                    modal.remove();
                    res('');
                }, 200);
            } else {
                err('No modal message');
            }
        });
    }

    public show(modal: HTMLElement) {
        modal.style.opacity = '1';
        modal.style.visibility = 'visible';
    }

    public hide(modal: HTMLElement) {
        modal.style.opacity = '0';
        modal.style.visibility = 'hidden';
    }

    changeTab(tab: HTMLElement) {
        if(tab.classList.contains('active')) {
            return;
        }

        [...document.querySelectorAll('.modal-tab.active')].forEach(e => e.classList.remove('active'));
        tab.classList.add('active');
        const tabID = tab.dataset['tabId'];
        const content = document.querySelector("#" + tabID) as HTMLElement;

        const allActiveTabs = ([...document.querySelectorAll('.scrollable > div')] as HTMLElement[]);
        allActiveTabs.forEach(e => {
            if(e.classList.contains('active')) {
                Animate.slideUp(e).then(() => {
                    e.classList.remove('active');
                    Animate.slideDown(content).then(() => content.classList.add('active'));
                });
            }
        });
    }
}