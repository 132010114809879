import { Ajax } from "./Ajax";
import { Animate } from './Animate';
import { Messages } from './Messages';
import { Modal } from "./Modal";

export class Cart {
    private modal: Modal

    private menuCart: HTMLElement;
    private cart: HTMLElement;

    constructor(modal: Modal) {
        this.modal = modal;

        this.menuCart = document.querySelector('#menu-cart') as HTMLElement;
        this.cart = document.querySelector('#cart') as HTMLElement;
        if(this.cart) {
            this.addEventListeners();
        }
    }

    public async addItem(id: number, quantity = 1, fasterDelivery = 0, modal = 0) {
        const data = new FormData();
        data.append('action', 'CA_AddToCart');
        data.append('id', id.toString());
        data.append('quantity', quantity.toString());
        data.append('fasterDelivery', fasterDelivery.toString());
        data.append('modal', modal.toString())

        return Ajax.requestForm(data).then(response => this.handleResponse(response));
    }

    private async onQuantityChange(input: HTMLInputElement) {
        const qty = parseInt(input.value);
        const item = input.closest('.item') as HTMLElement;

        const data = new FormData();
        data.append('action', 'CA_UpdateCartItem');
        data.append('key', item.dataset.key);
        data.append('qty', qty.toString());

        return Ajax.requestForm(data)
        .then(response => this.handleResponse(response))
        .then(() => {
            if(qty == 0) {
                Animate.slideUp(item).then(() => item.remove)
            }
        });
    }

    private async onTrashClick(element: HTMLElement) {
        const item = element.closest('.item') as HTMLElement;

        const data = new FormData();
        data.append('action', 'CA_RemoveCartItem');
        data.append('key', item.dataset.key);

        return Ajax.requestForm(data)
        .then(response => this.handleResponse(response))
        .then(() => Animate.slideUp(item).then(() => item.remove()));
    }

    private async onEmptyCartClick(element: HTMLElement) {
        const data = new FormData();
        data.append('action', 'CA_ClearCart');

        return Ajax.requestForm(data)
        .then(response => this.handleResponse(response));
    }

    private handleResponse(response: any) {
        if(response.success) {
            if(response.modal) {
                this.modal.add(response.modal);
            } else {
                Messages.show(response.success, response.messages)
            }
            this.updateMiniCart(response.cart);
            this.updateTotals(response.cart);
            if(response.cart.count == 0) {
                const cart = this.cart.querySelector('.items-start') as HTMLElement;
                Animate.slideUp(cart).then(() => {
                    cart.remove()

                    const empty = (new DOMParser().parseFromString(response.cart.empty, 'text/html').firstChild as HTMLElement);
                    empty.style.display = 'none';
                    this.cart.append(empty);
                    Animate.slideDown(empty);
                });
            }
        } else {
            Messages.show(response.success, response.messages)
        }

        return response;
    }

    private updateMiniCart(cart: any) {
        if(this.menuCart) {
            const total = this.menuCart.querySelector('.total');
            const counter = this.menuCart.querySelector('.counter') as HTMLElement;
            const hoverBox = this.menuCart.querySelector('.hover-box');

            total.innerHTML = cart.subtotal;
            if(counter.classList.contains('hidden')) {
                counter.classList.remove('hidden');
            }
            counter.innerHTML = cart.count;
            //reset animation
            counter.style.animation = 'none';
            counter.offsetHeight;
            counter.style.animation = null;

            hoverBox.remove();
            this.menuCart.innerHTML += cart.html;
        }
    }

    private updateTotals(cart: any) {
        if(this.cart) {
            this.cart.querySelector('.subtotal').innerHTML = cart.subtotal;
            this.cart.querySelector('.total').innerHTML = cart.total;

            const tax = this.cart.querySelector('.tax');
            if(tax) {
                tax.innerHTML = cart.tax;
            }
        }
    }

    private addEventListeners()
    {
        this.cart.querySelectorAll('.item').forEach(item => {
            item.querySelector('input').addEventListener('change', event => this.onQuantityChange(event.currentTarget as HTMLInputElement));
            item.querySelector('.trash').addEventListener('click', event => this.onTrashClick(event.currentTarget as HTMLElement));
        });

        this.cart.querySelector('#empty-cart')?.addEventListener('click', event => this.onEmptyCartClick(event.currentTarget as HTMLElement));
    }
}