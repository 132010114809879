export class Animate {
    static readonly SCROLL = 'scroll';

    /**
     */
    public static toggleHeaderShadow(header: HTMLElement) {
        if(window.scrollY == 0) {
            header.classList.remove(this.SCROLL);
        } else if(header.classList.contains(this.SCROLL) == false) {
            header.classList.add(this.SCROLL);
        }
    }

    public static blockElement(element: HTMLElement) {
        if(element.classList.contains('blockable')) {
            element.classList.add('block');
        }
    }

    public static unblockElement(element: HTMLElement) {
        if(element.classList.contains('blockable')) {
            element.classList.remove('block');
        }
    }

    public static unblockAllElements() {
        const elements = [...document.querySelectorAll('.blockable.block')];
        if(elements.length) {
            elements.forEach((element: HTMLElement) => element.classList.remove('block'));
        }
    }

    public static slideDown(element: HTMLElement) {
        element.style.removeProperty('display');
        let display = window.getComputedStyle(element).display;
        if (display === 'none') {
            display = 'block';
        }

        element.style.display = display;
        const height = element.offsetHeight;
        element.style.overflow = 'hidden';
        element.style.height = '0';
        element.style.setProperty('padding-top', '0px', 'important');
        element.style.setProperty('padding-bottom', '0px', 'important');
        element.style.setProperty('margin-top', '0px', 'important');
        element.style.setProperty('margin-bottom', '0px', 'important');
        element.offsetHeight;
        element.style.boxSizing = 'border-box';
        element.style.transitionProperty = "height, margin, padding";
        element.style.transitionDuration = 500 + 'ms';
        element.style.height = height + 'px';
        element.style.removeProperty('padding-top');
        element.style.removeProperty('padding-bottom');
        element.style.removeProperty('margin-top');
        element.style.removeProperty('margin-bottom');

        return new Promise((res) => {
            setTimeout(() => {
                element.style.removeProperty('height');
                element.style.removeProperty('overflow');
                element.style.removeProperty('transition-duration');
                element.style.removeProperty('transition-property');

                res(element);
            }, 500);
        })
    }

    public static slideUp(element: HTMLElement) {
        element.style.transitionProperty = 'height, margin, padding';
        element.style.transitionDuration = 500 + 'ms';
        element.style.boxSizing = 'border-box';
        element.style.height = element.offsetHeight + 'px';
        element.offsetHeight;
        element.style.overflow = 'hidden';
        element.style.height = '0px';
        element.style.setProperty('padding-top', '0px', 'important');
        element.style.setProperty('padding-bottom', '0px', 'important');
        element.style.setProperty('margin-top', '0px', 'important');
        element.style.setProperty('margin-bottom', '0px', 'important');
        
        return new Promise(res => {
            setTimeout(() => {
                element.style.display = 'none';
                element.style.setProperty('display', 'none', 'important');
                element.style.removeProperty('height');
                element.style.removeProperty('padding-top');
                element.style.removeProperty('padding-bottom');
                element.style.removeProperty('margin-top');
                element.style.removeProperty('margin-bottom');
                element.style.removeProperty('overflow');
                element.style.removeProperty('transition-duration');
                element.style.removeProperty('transition-property');

                res(element);
            }, 500);
        });
    }

    public static slideToggle(element: HTMLElement) {
        return window.getComputedStyle(element).display === 'none' ? this.slideDown(element) : this.slideUp(element);
    }
}